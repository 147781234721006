.footer {
  width: 100%;
  min-width: 225px;
  box-sizing: border-box;
  margin: auto 0 0;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #e3e5f2;
  background-color: var(--neutral-background);
  margin-top: 80px;
}

.footer__box {
  width: 100%;
  max-width: 1392px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: max-content;
  justify-items: center;
  column-gap: 50px;
}

.footer__logo-box {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.footer__logo {
  /* width: 100%; */
}

.footer__logo-img {
  /* width: 100%; */
  max-width: 176px;
  max-height: 26px;
  object-fit: scale-down;
  object-position: left;
}

.footer__copyright {
  color: #6f7387;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
}

.footer__copyright_type_mobile {
  display: none;
}

.footer__content {
  max-width: 658px;
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.footer__categories {
  width: 100%;
  display: grid;
  grid-template-columns: max-content;
  grid-auto-columns: max-content;
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  justify-content: space-between;
  text-align: left;
  gap: 0.65rem 40px;
}

.footer__links {
  width: 100%;
  border-top: 1px solid #e3e5f2;
  display: flex;
  gap: 0 40px;
  /* grid-template-columns: 0.43fr 0.2fr 0.3fr;
  grid-template-rows: 1fr 1fr;*/
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
}

.footer__content-box {
  max-width: 1392px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  /* grid-template-columns: 0.43fr 0.2fr 0.3fr;
  grid-template-rows: 1fr 1fr;*/
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.footer__content-for-buyer {
  display: flex;
  flex-direction: column;
}

.footer__content_contacts {
  display: flex;
  flex-direction: column;
}

.footer__content_link {
  color: #1c1c1c;
  line-height: 1.4375rem;
  letter-spacing: -0.04375rem;
}

.footer__content_title {
  color: var(--primary-active);
}

.footer__content-email-subscribe {
}

.footer__email-block {
  border-radius: 30px;
  border: 1px solid var(--primary-active);
  box-sizing: border-box;
  padding: 2px 2px 2px 12px;
  display: flex;
  height: 2rem;
  margin-top: 0.88rem;
  align-items: center;
  gap: 8px;
}

.footer__email-block_button {
  height: 100%;
  background-color: var(--primary-active);
  border-radius: 30px;
  /* padding: 0.62rem; */
  padding: 0 10px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--website_background-background);
  text-align: right;
  transition: opacity 0.3s ease-in;
}

.footer__link {
  color: var(--neutral-active);

  line-height: 1.25rem;
  margin-top: 0.65rem;
  font-size: var(--caption-size);
  font-weight: var(--caption-weight);
}

.footer__link_margin_zero {
  margin: 0;
}

.footer__text-privecy {
  color: var(--neutral-active);

  line-height: 0.75rem;
  margin-top: 0.75rem;
}

.footer__text-privecy_link {
  text-decoration: underline;
}

.footer__email-block_inactive {
  opacity: 0.3;
  pointer-events: none;
}

.footer__polza {
  width: 102px;
  height: 50px;
}

.footer__polza-fill {
  fill: var(--neutral-active);
}

.footer__socials {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
}

.footer__links{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
}

.footer__social {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

}

.footer__social-icon {
  width: 32px;
  height: 32px;
}

.footer__social-icon-fill {
  fill: var(--website_background-background);
}

.footer__social-icon-bg-fill {
  fill: var(--neutral-active);
}

.footer__social-title{
  color: var(--neutral-active);

  line-height: 0.75rem;
  font-size: var(--paragrah-size);
  font-weight: var(--paragrah-weight);
}

@media (max-width: 1200px) {
  .footer {
    padding: 60px 32px;
  }
}

@media (max-width: 1100px) {
  .footer__content {
    grid-template-columns: 0.35fr 0.15fr 0.4fr;
  }
}

@media (max-width: 1000px) {
  .footer {
    padding: 2.5rem 1rem 1.9rem;
    margin-top: 40px;
  }

  .footer__categories {
    grid-template-rows: repeat(3, 1fr);
  }
}

@media (max-width: 930px) {
  .footer__email-block {
    /* height: 1.5rem; */
  }
}

@media (max-width: 800px) {
  .footer__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .footer__copyright {
    display: none;
  }

  .footer__copyright_type_mobile {
    display: block;
  }
}

@media (max-width: 500px) {
  .footer__categories {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
  }

  .footer__email-block {
    margin-top: 0;
  }
  .footer__socials {
justify-content: center;
  }
}

@media (max-width: 800px) {
  .footer__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}
