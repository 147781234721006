.reviews {
  width: 100vw;
  border-top: 1px solid #e3e5f2;
  padding-top: 80px;
}

.reviews__container {
  width: 100%;
  max-width: 1344px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 32px;
  margin: 0 auto;
}

.reviews__title {
  width: 100%;
  color: var(--secondary-active);
  font-family: 'Playfair Display';
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.09;
}

.reviews__swiper-box {
  width: calc(100% + 16px);
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
}

.reviews__swiper {
  width: 100%;
  position: relative;
  z-index: 0;
}

.reviews__slide {
  height: auto !important;
  box-sizing: border-box;
  padding: 30px 8px;
}

.reviews__swiper-btn {
  width: 48px;
  height: 48px;
  position: absolute;
  z-index: 1;
  transition: opacity 0.2s ease-in-out;
}

.reviews__swiper-btn_type_prev {
  left: -16px;
}

.reviews__swiper-btn_type_next {
  right: -16px;
}

.reviews__swiper-btn_hidden {
  display: none;
}

.reviews__swiper-btn_type_opacity {
  opacity: 0.7;
  pointer-events: none;
  user-select: none;
}

.reviews__arrow-icon {
  width: 68px;
  height: 68px;
  position: relative;
  left: -10px;
  top: -6px;
}

.reviews__arrow-icon_type_reverse {
  transform: scaleX(-1);
}

.reviews__arrow-icon-fill {
  fill: var(--neutral-background);
}

.reviews__arrow-icon-stroke {
  stroke: var(--secondary-active);
}

@media (max-width: 1000px) {
  .reviews {
    padding-top: 40px;
  }

  .reviews__container {
    padding: 0 16px;
  }

  .reviews__title {
    font-size: 34px;
    line-height: 1.12;
  }

  .reviews__swiper-box {
    width: calc(100% + 16px * 2);
    margin-top: 4px;
  }

  .reviews__swiper {
    box-sizing: border-box;
    padding: 0 11px !important;
  }

  .reviews__slide {
    padding: 16px 5px 26px;
  }

  .reviews__swiper-btn {
    display: none;
  }

  .reviews__swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0 !important;
  }

  .reviews__swiper-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    background-color: #d9d9d9;
    cursor: pointer;
  }

  .reviews__swiper-bullet_type_active {
    background-color: var(--secondary-active);
  }
}
