.zamer-info {
  width: 100%;
  /* max-width: 1124px; */
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
  align-self: flex-start;
  margin-top: 20px;
}

.zamer-info__img {
  width: 44.84%;
  max-width: 504px;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

.zamer-info__text-block {
  max-width: 500px;
}

.zamer-info__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.zamer-info__item {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  gap: 16px;
}

.zamer-info__item_tabs {
  /* border: 1px solid #000; */
  border-radius: 600px;
  padding: 11px 24px;
  background-color: #F5F3F0;
  transition: all ease-in-out 0.3s;
}

.zamer-info__number {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #f5f3f0;

  color: var(--neutral-active);
  text-align: center;
  font-family: 'Tenor Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.12;
}

.zamer-info__text {
  color: var(--neutral-active);
  font-family: 'Tenor Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.zamer-info__text_size_small {
  font-size: 14px;
}

.zamer-info__block {
  border-top: 1px solid #e3e5f2;
  box-sizing: border-box;
  padding-top: 40px;
  margin-top: 40px;
}

.zamer-info__icon {
  width: 30px;
  height: 30px;
}

.zamer-info__icon-fill {
  fill: var(--secondary-active);
}

.zamer-info__tabs {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}

.zamer-info__tabs .zamer-info__text {
  font-size: 18px;
}

.zamer-info__tabs_active {
  background-color: #c0a274;
  transition: all ease-in-out 0.3s;
}

.zamer-info__tabs_active .zamer-info__text {
  color: #F5F3F0;
  transition: all ease-in-out 0.3s;
}

@media (max-width: 1000px) {
  .zamer-info__item {
    grid-template-columns: 26px 1fr;
  }

  .zamer-info__number {
    width: 26px;
    height: 26px;
    font-size: 14px;
  }

  .zamer-info__text {
    font-size: 14px;
  }

  .zamer-info__text_size_small {
    font-size: 12px;
  }

  .zamer-info__block {
    padding-top: 24px;
    margin-top: 24px;
  }

  .zamer-info__icon {
    width: 26px;
    height: 26px;
  }

  .zamer-info__tabs {
    justify-content: flex-start;
  }

  .zamer-info__tabs .zamer-info__text {
    font-size: 14px;
  }

  .zamer-info__item_tabs {
    padding: 6px 14px;
  }
}

@media (max-width: 800px) {
  .zamer-info {
    flex-direction: column;
    align-items: center;
  }

  .zamer-info__img {
    width: 100%;
    max-width: 400px;
  }

  .zamer-info__text-block {
    max-width: 100%;
  }
}