.review-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: var(--neutral-background);
  box-shadow: 0px 6px 15px 0px rgba(239, 239, 239, 0.8);
  box-sizing: border-box;
  padding: 10px 10px 30px;
}

.review-card__img {
  width: 100%;
  aspect-ratio: 99/54;
  object-fit: cover;
  border-radius: 4px;
}

.review-card__text-block {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.review-card__title {
  color: var(--neutral-active);
  font-family: 'Playfair Display';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.09;
}

.review-card__city {
  color: #6f7387;
  font-family: 'Tenor Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43;
  padding-top: 6px;
}

.review-card__text {
  max-width: 356px;
  color: var(--neutral-active);
  font-family: 'Tenor Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43;
  padding-top: 14px;
}

@media (max-width: 1000px) {
  .review-card {
    padding: 6px 6px 30px;
  }

  .review-card__img {
    aspect-ratio: 41/27;
  }

  .review-card__text-block {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 10px 0;
  }
}
