.zamer-variants {
    width: 100%;
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;
    gap: 32px;
    align-self: center;
    /* border-top: 1px solid #c0a274; */
    /* padding-top: 40px; */
}

.zamer-variants__title {
    font-weight: 700;
    font-family: 22px;
    /* text-align: center; */
    scroll-margin-top: 40px;
    /* margin: 0 auto; */
    font-size: 24px;
}

.zamer-info__list_variants {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* list-style: circle !important; */
}

.zamer-variants__img_first {
    max-width: 400px !important;
}

.zamer-variants__img {
    margin: 0 auto;
    max-width: fit-content;
    width: 100%;
}