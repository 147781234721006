.project-popup {
  padding: 20px 32px;
}

.project-popup .popup__container {
  max-width: 1280px;
  height: calc(100vh - 20px * 2);
  padding: 40px 80px;
}

.project-popup__close-btn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.project-popup__close-icon {
  width: 100%;
  height: 100%;
}

.project-popup__content {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.project-popup__content::-webkit-scrollbar {
  display: none;
}

.project-popup__text-block {
  display: flex;
  align-items: center;
}

.project-popup__text {
  color: #6f7387;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.33;
  padding-bottom: 6px;
}

.project-popup__heading {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
}

.project-popup__title {
  width: 70%;
  color: var(--neutral-active);
  font-family: 'Playfair Display';
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.05;
}

.project-popup__btns-block {
  display: flex;
  align-items: center;
  gap: 20px;
}

.project-popup__btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--primary-active);
  box-sizing: border-box;
  padding: 9px;
  transition: all 0.2s linear;
}

.project-popup__btn:hover {
  background: #fff2d9;
}

.project-popup__btn:active {
  background: var(--secondary-active);
}

.project-popup__btn-icon {
  width: 100%;
  height: 100%;
}

.project-popup__btn-icon-stroke {
  stroke: #e3e5f2;
  transition: all 0.2s linear;
}

.project-popup__btn:active .project-popup__btn-icon-stroke {
  stroke: var(--primary-active);
}

.project-popup__subtitle {
  color: #6f7387;
  font-family: 'Tenor Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  padding-top: 10px;
}

.project-popup__grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  gap: 8px;
  margin-top: 50px;
}

.project-popup__grid-item {
  width: 100%;
  height: 100%;
  display: flex;
}

.project-popup__img {
  width: 100%;
  aspect-ratio: 92/75;
  object-fit: cover;
  border-radius: 4px;
}

@media (max-width: 1000px) {
  .project-popup {
    padding: 16px 20px;
  }

  .project-popup .popup__container {
    padding: 32px 16px;
  }

  .project-popup__close-btn {
    top: 10px;
    right: 10px;
  }

  .project-popup__text {
    font-size: 10px;
  }

  .project-popup__heading {
    flex-direction: column;
    gap: 16px;
  }

  .project-popup__title {
    width: 90%;
    font-size: 26px;
  }

  .project-popup__btns-block {
    gap: 12px;
  }

  .project-popup__subtitle {
    font-size: 14px;
  }

  .project-popup__grid {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 4px;
    margin-top: 32px;
  }
}