.heading {
  width: 100vw;
}

.heading__container {
  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.heading__content {
  max-width: 490px;
  box-sizing: border-box;
  padding: 0 32px;
  margin: 0 auto;
}

.heading__title {
  color: var(--neutral-background);
  font-family: 'Playfair Display';
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 0.98;
}

.heading__subtitle {
  color: var(--neutral-background);
  font-family: 'Tenor Sans';
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  padding-top: 20px;
}

.heading__btns-block {
  display: flex;
  gap: 12px;
  margin-top: 34px;
}

.heading__btn {
  min-width: 160px;
  color: var(--neutral-active);
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  font-style: normal;
  font-weight: 550;
  line-height: 1.3;
  border-radius: 8px;
  background-color: var(--neutral-background);
  backdrop-filter: blur(6.5px);
  box-sizing: border-box;
  padding: 16px 24px;
  transition: background-color 0.2s ease-in-out;
}

.heading__btn:hover {
  background-color: #fff8e9;
}

.heading__btn:active {
  background-color: #f6f6f6;
}

.heading__btn_type_link {
  color: var(--neutral-background);
  background-color: var(--secondary-active);
}

.heading__btn_type_link:hover {
  background-color: #deb983;
}

.heading__btn_type_link:active {
  background-color: #ae8f60;
}

.heading__img {
  width: 59.31%;
  max-width: 854px;
  aspect-ratio: 427/300;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .heading__container {
    display: grid;
    grid-template-columns: fit-content(446px) 1fr;
  }

  .heading__img {
    width: 100%;
    min-height: 488px;
    aspect-ratio: unset;
  }
}

@media (max-width: 1000px) {
  .heading__container {
    grid-template-columns: fit-content(390px) 1fr;
  }

  .heading__content {
    padding: 0 16px;
  }

  .heading__title {
    font-size: 54px;
    line-height: 0.93;
  }

  .heading__subtitle {
    font-size: 18px;
    padding-top: 14px;
  }

  .heading__btns-block {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 14px;
    margin-top: 26px;
  }

  .heading__btn {
    min-width: unset;
    font-size: 14px;
    line-height: 1.43;
  }

  .heading__btn:hover {
    background-color: var(--neutral-background);
  }

  .heading__btn:active {
    background-color: var(--neutral-background);
  }

  .heading__btn_type_link:hover {
    background-color: var(--secondary-active);
  }

  .heading__btn_type_link:active {
    background-color: var(--secondary-active);
  }

  .heading__img {
    min-height: 345px;
  }
}

@media (max-width: 700px) {
  .heading__container {
    display: flex;
    flex-direction: column-reverse;
  }

  .heading__content {
    width: 100%;
    max-width: 100%;
    padding: 20px 16px 40px;
  }

  .heading__img {
    max-height: 345px;
    min-height: 260px;
  }
}
