.portfolio-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  gap: 8px;
}

.portfolio-list__card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--secondary-active);
  box-sizing: border-box;
  padding: 40px 20px 20px;
}

.portfolio-list__card-logo {
  color: var(--secondary-active);
  font-family: 'Playfair Display';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 1.28px;
}

.portfolio-list__title-box {
  display: flex;
  flex-direction: column;
  flex: auto;
  align-items: center;
  justify-content: center;
  gap: 18px;
}

.portfolio-list__card-number {
  color: var(--secondary-active);
  font-family: 'Playfair Display';
  font-size: 90px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.03;
}

.portfolio-list__card-title {
  color: var(--neutral-active);
  font-family: 'Tenor Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.22;
  text-transform: uppercase;
}

.portfolio-list__card-btn {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neutral-background);
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
  line-height: 1.25;
  border-radius: 8px;
  background-color: var(--secondary-active);
  backdrop-filter: blur(6.5px);
  transition: background-color 0.2s ease-in-out;
}

.portfolio-list__card-btn:hover {
  background-color: #deb983;
}

.portfolio-list__card-btn:active {
  background-color: #ae8f60;
}

@media (max-width: 1250px) {
  .portfolio-list__title-box {
    gap: 12px;
  }

  .portfolio-list__card-number {
    font-size: 90px;
  }

  .portfolio-list__card-title {
    font-size: 13px;
  }
}

@media (max-width: 1000px) {
  .portfolio-list {
    grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
    gap: 4px;
  }

  .portfolio-list__card {
    padding: 20px 10px 10px;
  }
}
