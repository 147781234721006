.card-links {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.card-links__link {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card-links__img {
  width: 100%;
  aspect-ratio: 158/95;
  object-fit: cover;
  border-radius: 4px;
}

.card-links__text-block {
  width: 100%;
  box-sizing: border-box;
  padding: 16px 10px 0;
}

.card-links__title {
  color: var(--neutral-active);
  font-family: 'Playfair Display';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.12;
}

.card-links__text {
  color: #6f7387;
  font-family: 'Tenor Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.22;
  padding-top: 6px;
}

@media (max-width: 1000px) {
  .card-links__text-block {
    padding: 16px 0 0;
  }
}

@media (max-width: 700px) {
  .card-links {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .card-links__img {
    aspect-ratio: 179/100;
  }
}
