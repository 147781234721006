.full-screen-image {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.88);
    box-sizing: border-box;
    padding: 12px;
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.full-screen-image_active {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;

    z-index: 999;
}

.full-screen-image__img {
    position: relative;
    z-index: 1000;
    max-width: 90vw;
    max-height: 90vh;
    border-radius: 16px;
}

.full-screen-image__bg {
    position: absolute;
    background-color: rgba(130, 114, 82, 0.4);
    width: 100%;
    height: 100%;
    z-index: 800;
}

.full-screen-image__close {
    position: absolute;
    z-index: 10000;
    right: 40px;
    top: 40px;
    cursor: pointer;
}

.full-screen-image__close-icon {
    width: 40px;
    height: 40px;
}

.full-screen-image__close-icon-stroke {
    stroke: var(--bg-color);
    stroke-width: 4px;
}