.search {

    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    padding: 0 0 48px;
}

.search__head {
    box-sizing: border-box;
    padding: 20px 60px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 42px;
    position: relative;
    z-index: 11;
    background-color: var(--website_background-background);;
}

.search__first-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;



}

.search__location {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.search__location-icon {
    width: 24px;
    height: 24px;
}

.search__location-icon-fill {
    fill: var(--contrast-color);
}

.search__location-name {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: var(--text-color);
    margin: 0;
}

.search__phone-and-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;

}

.search__phone {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    color: var(--text-color);
    margin: 0;
}

.search__time {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.search__time-value {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: var(--website_background-background);;
    margin: 0;
}

.search__second-row {
    display: grid;
    grid-template-columns: 70px 1fr max-content;
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    align-content: space-around;
    justify-content: space-between;
    column-gap: 30px;
    width: 100%;
    z-index: 11;
    position: relative;
}

.search__logo {
    width: 69px;
    height: 60px;
}

.search__logo-fill {
    fill: var(--contrast-color);
}

.search__hendlers {

    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.search__btn-box {
    position: relative;
}

.search__btn {
    border-radius: 1000px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 8px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */



}

.search__btn-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin: 0;
}

.search__btn_type_catalog {
    background-color: var(--contrast-color);

}

.search__btn_type_catalog .search__btn-text {
    color: var(--website_background-background);;
}



.search__btn_type_rooms .search__btn-text {
    color: var(--contrast-color);
}

.search__btn-icon {
    width: 20px;
    height: 20px;
}

.search__btn_type_catalog .search__btn-icon-fill {
    fill: var(--website_background-background);;
}

.search__btn_type_catalog .search__btn-icon-stroke {
    stroke: var(--website_background-background);;
}

.search__btn_type_rooms .search__btn-icon-fill {
    fill: var(--contrast-color);
}

.search__btn_type_rooms .search__btn-icon-stroke {
    stroke: var(--contrast-color);
}

.search__input-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    position: absolute;
    /* overflow: hidden; */

    top: 0;
    height: 100%;
    z-index: 4;
    background-color: var(--website_background-background);
}

.search__input-container_visible {
    overflow: initial;
}

.search__input-container_unvisible {
    width: 0;
    overflow: hidden;
}

.search__search-box {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 12px;
    padding: 12px 0;
    box-sizing: border-box;
    background-color: var(--website_background-background);
}

.search__search-icon {
    width: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search__input-box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--primary-active);

    border-radius: 6px;
    background: var(--website_background-background);

    box-sizing: border-box;
    /* padding: 16px 16px 16px 24px; */
    padding: 4px 4px 4px 40px;
}

.search__input {
    caret-color: var(--neutral-active);
    -webkit-appearance: none;
    width: calc(100% - 12px - 24px);
    box-sizing: border-box;
    /* width: calc(100% - 24px - 12px * 2 - 12px); */
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    border: none;
    padding: 0;
    margin-top: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--neutral-active);
}

.search__input:-webkit-autofill {
    background: var(--neutral-active);
}

.search__input:focus {
    outline: none;
}

.search__input-icon {
    width: 24px;
    height: 24px;
}

.search__input-icon-fill {
    fill: var(--contrast-color);
}

.search__main-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.search__main-link {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.search__main-link-icon-and-counter {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 12px 0 0;

}

.search__main-link-icon {
    width: 20px;
    height: 20px;
}

.search__main-link-icon-stroke {
    stroke: var(--text-color);
}

.search__main-link-counter {
    position: absolute;
    bottom: 13px;
    left: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    box-sizing: border-box;
    margin: 0;
    color: #FFFFFF;
    background-color: var(--contrast-color);
    border-radius: 1000px;
    padding: 2px 4px;
    text-align: center;
}

.search__main-link-name {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    /* identical to box height, or 17px */

    text-align: center;

    /* colour/text/tetriary */

    color: rgba(18, 18, 18, 0.56);
    margin: 12px 0 0;
}

.search__main-links_mobile {
    display: none;
}

.search__logo-box_mobile {
    display: none;
}

.search__mobile-btns {
    display: none;
}

.search__menu {}

.search__menu-icon {
    width: 34px;
    height: 34px;
}

.search__menu-icon-fill {
    fill: var(--text-color);
}

.search__btn-icon_close-catalog {
    position: absolute;
    z-index: 3;
    opacity: 0;
    background-color: var(--contrast-color);
    transition: all 0.2s ease-in-out;
}

.search__btn-icon_close-catalog-open {
    opacity: 1;
}

.search__btn-icon-rooms-fill {
    fill: var(--contrast-color);
}

.search__btn-icon_close-rooms {
    position: absolute;
    z-index: 3;
    opacity: 0;
    background-color: var(--website_background-background);;
    transition: all 0.2s ease-in-out;
}

.search__btn-icon_close-rooms-open {
    opacity: 1;
}

.search__btn-search {
    border-radius: 6px;
    background: var(--primary-active);
    color: var(--website_background-background);
    /* font-family: Outfit; */
    font-size: 12px;
    font-style: normal;
    font-weight: 550;
    line-height: 16px;
    /* 133.333% */
    height: 100%;
    padding: 0px 16px;

    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1505px) {
    .search__head {
        padding: 20px 20px 24px;

    }
}

@media (max-width: 1250px) {
    .search__input-container {
        /* width: 100%; */
        max-width: 80%;
    }
}

@media (max-width: 1100px) {
    .search__input-container {
        /* width: 100%; */
        max-width: 80%;
    }

    .search__search-box {
        gap: 10px;
    }
}

@media (max-width: 900px) {
    .search__input-container {
        max-width: 75%;
    }
}

@media (max-width: 780px) {
    .search__input-container {
        /* display: none; */
        top: 60px;
        left: 0;
        padding: 0 16px 8px;
        box-sizing: border-box;
        height: unset;
        max-width: 100%;
        background-color: var(--website_background-background);
    }

    .search__search-icon {
        /* display: none; */
    }

    .search__input-box {
        padding: 4px;
        height: 44px;
    }

    .search__input-icon {
        width: 16px;
        height: 16px;
        margin-top: 30px;
    }

    .search__logo {
        width: 69px;
        height: 60px;
    }

    .search__input {
        font-size: 14px;
        /* margin-left: 30px; */
    }
    .search__btn-search{
        padding: 0 16px;
    }
}