.zamer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  scroll-behavior: smooth;
}

.zamer__content {
  width: 100%;
  max-width: 844px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 80px;
}

@media (max-width: 1000px) {
  .zamer__content {
    padding: 0 16px;
    gap: 40px;
  }
}
