.contact-popup__close-btn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.contact-popup__close-icon {
  width: 100%;
  height: 100%;
}

.contact-popup__content {
  width: 100%;
  min-height: 366px;
  display: flex;
  flex-direction: column;
}

.contact-popup__logo {
  color: var(--secondary-active);
  font-family: 'Playfair Display';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.14;
  letter-spacing: 1.12px;
}

.contact-popup__title {
  color: var(--neutral-active);
  font-family: 'Playfair Display';
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.13;
  padding-top: 12px;
}

.contact-popup__title_type_success {
  text-align: center;
  margin: auto 0 auto;
  padding: 0;
}

.contact-popup__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.contact-popup__inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contact-popup__checkbox {
  width: 100%;
  margin-top: 16px;
}

.contact-popup__submit-btn {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.25;
  padding: 12px 40px;
  margin-top: 24px;
  transition: opacity 0.2s ease-in-out;
}

.contact-popup__submit-btn_disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}
