.header {
    width: 100%;

    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    position: sticky;
    transition: all 0.3s ease-in-out;
    z-index: 11;
    background-color: var(--website_background-background);
}

.header_hide {
    top: -200px;
}

.header_show {
    top: 0;
}

.header__input-icon {
    position: relative;
    z-index: 5;
    margin-left: 12px;
}

.header__input-icon-fill {
    fill: var(--neutral-active);
}

.header__content {
    max-width: 1280px;
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    column-gap: 32px;
    align-items: center;
    position: relative;
    z-index: 11;
    background-color: var(--website_background-background);
    padding: 0 32px;
    box-sizing: border-box;
}

.header__logo {
    width: 154px;
    height: 40px;
    /* width: 100%; */
}

.header__logo-img {
    /* width: 100%; */
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: left;
}

.header__catalog-with-links {
    display: grid;
    grid-template-columns: max-content 24px minmax(0, 1fr);
    grid-template-rows: max-content;
    align-items: center;
    column-gap: 28px;
    position: relative;
}

.header__catalog {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}

.header__catalog-btn {
    background-color: var(--secondary-active);
    padding: 14px 16px;
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 1));
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.header__catalog-with-categories {
    height: 100%;
    display: grid;
    grid-template-columns: max-content minmax(0, 1fr);
    grid-template-rows: 1fr;
    align-items: center;
    align-content: center;
    column-gap: 24px;
}

.header__catalog-btn-text {
    color: var(--website_background-background);
}

.header__categories-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;

    justify-content: space-between;
}

.header__categories-box__main-and-catalog {
    display: flex;
}

.header__categories {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
}

.header__category {
    font-size: var(--h4-size);
    font-weight: var(--h4-weight);
    color: var(--neutral-active);
    transition: all 0.2s linear;

    flex: none;
    padding: 0 17px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.header__category_active {
    background: #F5F3F0;
}

.header__active-icon {
    width: 20px;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 0.2s linear;
}

.header__category_active .header__active-icon {
    opacity: 1;
}

.header__active-icon-fill {
    fill: var(--secondary-active);
}

.header__cart-and-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
    padding: 16px 0;
}

.header__link-btn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    background: var(--primary-active);
    box-sizing: border-box;
    padding: 9px;
    transition: all 0.2s linear;
}

.header__link-btn:hover {
    background: #FFF2D9;
}

.header__link-btn:active {
    background: var(--secondary-active);
}

.header__btn-icon {
    width: 100%;
    height: 100%;
}

.header__btn-icon-stroke {
    stroke: var(--website_background-background);
    transition: all 0.2s linear;
}

.header__link-btn:hover .header__btn-icon-stroke {
    stroke: var(--primary-active);
}

.header__link-btn:active .header__btn-icon-stroke {
    stroke: var(--website_background-background);
}

.header__cart-count {
    font-size: var(--caption-size);
    font-weight: var(--caption-weight);

    position: absolute;
    right: -6px;
    top: -6px;
    background-color: var(--primary-background);
    color: var(--website_background-background);
    border-radius: 50%;
    min-width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    box-sizing: border-box;
}

.header__catalog-btn-icon {
    width: 20px;
    height: 20px;
}

.header__catalog-btn-icon-fill {
    fill: var(--website_background-background);
}

.header__catalog-btn-icon_close-catalog {
    position: absolute;
    z-index: 3;
    opacity: 0;
    background-color: var(--secondary-active);
    transition: all 0.2s ease-in-out;
}

.header__catalog-btn-icon_close-catalog-open {
    opacity: 1;
}

.header__menu {
    display: none;
}

.header__menu-icon {
    width: 18px;
    height: 18px;
}

.header__menu-icon-fill {
    fill: var(--neutral-active);
}

.header__cart-and-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
}

.header__links {
    width: 100%;
    display: flex;
    overflow: hidden;
    white-space: nowrap;

    position: relative;
}

.header__link {
    flex: none;
    padding: 0 28px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.2s linear;
}

.header__link_active {
    color: var(--primary-active);
}

.header__search-box {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.search_mobile {
    display: none;
}

.search_pc {
    display: flex;
}

.header__search-icon-mobile {
    display: none;
    padding: 12px 12px;
}

@media (max-width: 1000px) {
    .header__catalog-with-links {
        column-gap: 6px;
    }
}

@media (max-width: 780px) {
    .search_mobile {
        display: flex;
    }

    .header__search-icon-mobile {
        display: flex;
    }

    .search_pc {
        display: none;
    }

    .header__catalog-with-categories {
        display: none;
    }

    .header__content {
        padding: 0 16px;
    }

    .header__menu {
        display: flex;
    }

    .header__input-icon {
        margin-top: 30px;
    }

    .header__catalog-with-links {
        display: none;
    }

    .header__logo {
        width: 112px;
        height: 16px;
        text-align: center;
    }


    .header__cart-and-menu {
        gap: 8px;
        padding: 10px 0;
    }

    .header__link-btn {
        width: 26px;
        height: 26px;
        padding: 5.85px;
    }

    .header__cart-count {
        font-size: 8px;
        right: -3px;
        top: -3px;
        min-width: 12px;
        height: 12px;
    }
}