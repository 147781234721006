.checkbox {
  width: 100%;
}

.checkbox__container {
  width: 100%;
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 10px;
}

.checkbox__invisible-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.checkbox__pseudo-item {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--secondary-active);
  background: #faf9f8;
  cursor: pointer;
}

.checkbox__check-icon {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.checkbox__pseudo-item_checked .checkbox__check-icon {
  opacity: 1;
}

.checkbox__icon-fill {
  fill: var(--secondary-active);
}

.checkbox__check-icon-fill {
  fill: var(--neutral-background);
}

.checkbox__label {
  color: #6f7387;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.17;
}
