.portfolio-card {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
}

.portfolio-card__link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.portfolio-card__img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.portfolio-card__info {
  width: 100%;
  flex: auto;
  box-sizing: border-box;
  padding: 20px 20px 16px;
}

.portfolio-card__text-block {
  display: flex;
  align-items: center;
}

.portfolio-card__text {
  color: var(--neutral-background);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  opacity: 0.7;
  padding-bottom: 8px;
}

.portfolio-card__title {
  color: var(--neutral-background);
  font-family: 'Playfair Display';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.11;
}

.portfolio-card__subtitle {
  color: var(--neutral-background);
  font-family: 'Tenor Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.28;
  padding-top: 4px;
}

.portfolio-card__btns-block {
  display: flex;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
  padding: 0 20px 24px;
}

.portfolio-card__btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  padding: 9px;
}

.portfolio-card__btn-icon {
  width: 100%;
  height: 100%;
}

.portfolio-card__btn-icon-stroke {
  stroke: var(--neutral-background);
}

@media (max-width: 1000px) {
  .portfolio-card__info {
    padding: 10px 10px 16px;
  }

  .portfolio-card__text {
    padding-bottom: 4px;
  }

  .portfolio-card__title {
    font-size: 14px;
  }

  .portfolio-card__subtitle {
    font-size: 12px;
  }

  .portfolio-card__btns-block {
    gap: 12px;
    padding: 0 10px 16px;
  }
}
