.txtimg-block {
  width: 100vw;
  background: #fcfaf6;
}

.txtimg-block__container {
  width: 100%;
  max-width: 1344px;
  display: flex;
  align-items: center;
  gap: 84px;
  box-sizing: border-box;
  padding: 40px 32px;
  margin: 0 auto;
}

.txtimg-block__img {
  width: 44.06%;
  max-width: 564px;
  aspect-ratio: 141/172;
  object-fit: cover;
  border-radius: 4px;
}

.txtimg-block__img_type_mobile {
  display: none;
}

.txtimg-block__content {
  max-width: 540px;
}

.txtimg-block__title-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.txtimg-block__subtitle {
  color: var(--neutral-active);
  font-family: 'Playfair Display';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.09;
}

.txtimg-block__title {
  color: var(--secondary-active);
  font-family: 'Playfair Display';
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.04;
}

.txtimg-block__icon {
  width: 62px;
  height: 18px;
  margin-top: 58px;
}

.txtimg-block__icon-fill {
  fill: var(--secondary-active);
}

.txtimg-block__text {
  color: var(--neutral-active);
  font-family: 'Tenor Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.38;
  padding-top: 71px;
}

@media (max-width: 1200px) {
  .txtimg-block__container {
    gap: 32px;
  }

  .txtimg-block__icon {
    margin-top: 20px;
  }

  .txtimg-block__text {
    padding-top: 20px;
  }
}

@media (max-width: 1000px) {
  .txtimg-block__container {
    gap: 20px;
    padding: 40px 16px;
  }

  .txtimg-block__img {
    min-height: 525px;
  }

  .txtimg-block__text {
    font-size: 14px;
    line-height: 1.43;
  }
}

@media (max-width: 700px) {
  .txtimg-block__container {
    padding: 40px 16px 60px;
  }

  .txtimg-block__img {
    display: none;
  }

  .txtimg-block__img_type_mobile {
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 400px;
    min-height: 300px;
    aspect-ratio: 179/150;
  }

  .txtimg-block__content {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .txtimg-block__title-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .txtimg-block__icon {
    margin-top: 0;
  }

  .txtimg-block__text {
    padding-top: 0;
  }
}
